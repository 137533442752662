import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";




import { MdManageAccounts } from "react-icons/md";
import { FaUserDoctor } from "react-icons/fa6";
import { RiAdminLine } from "react-icons/ri";
import { HiOutlineBanknotes } from "react-icons/hi2";
import { MdOutlineEditNote } from "react-icons/md";
import { ImLab } from "react-icons/im";

const UniversalLogin = () => {

  return (
    <>
      <Container>
        <section className="vh-100">
          <div className="container h-100 ">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-lg-12 col-xl-11">
                <div className="text-black cardstyle shadow">
                  <div className="card-body p-5">
                    <div className="row justify-content-center">
                      <div className="col-md-10 col-lg-6 col-xl-5 order-2">
                        <p className="text-center h4 fw-bold mb-5 mx-1 mx-md-4 mt-4 heading">
                          Please Select User
                        </p>
                        <div class="d-grid gap-4">
                          <Link
                            target="_blank"
                            to={"https://dentalguru-superadmin.vimubds5.a2hosted.com/"}
                          >
                            {" "}
                            <button
                              className="btn btn-primary fs-4"
                              type="button"
                              style={{ width: "100%" }}
                            >
                              <span className="icon">
                                <RiAdminLine />
                              </span>{" "}
                              Super Admin
                            </button>
                          </Link>
                          <Link
                            target="_blank"
                            to={"https://dentalguru-admin.vimubds5.a2hosted.com/"}
                          >
                            <button
                              class="btn btn-primary fs-4"
                              type="button"
                              style={{ width: "100%" }}
                            >
                              <span className="icon">
                                {" "}
                                <MdManageAccounts />
                              </span>{" "}
                              Admin
                            </button>
                          </Link>

                          <Link
                            target="_blank"
                            to={"https://dentalguru-accountant.vimubds5.a2hosted.com/"}
                          >
                            <button
                              class="btn btn-primary fs-4"
                              type="button"
                              style={{ width: "100%" }}
                            >
                              <span className="icon">
                                <HiOutlineBanknotes />
                              </span>{" "}
                              Accountant
                            </button>
                          </Link>

                          <Link
                            target="_blank"
                            to={"https://dentalguru-receptionist.vimubds5.a2hosted.com/"}
                          >
                            <button
                              class="btn btn-primary fs-4"
                              type="button"
                              style={{ width: "100%" }}
                            >
                              <span className="icon">
                                <MdOutlineEditNote />
                              </span>{" "}
                              Receptionist
                            </button>
                          </Link>

                          <Link
                            target="_blank"
                            to={"https://dentalguru-doctor.vimubds5.a2hosted.com/"}
                          >
                            <button
                              class="btn btn-primary fs-4"
                              type="button"
                              style={{ width: "100%" }}
                            >
                              <span className="icon">
                                <FaUserDoctor />
                              </span>{" "}
                              Doctor
                            </button>
                          </Link>

                          <Link
                            target="_blank"
                            to={"https://dentalguru-lab.vimubds5.a2hosted.com/"}
                          >
                            <button
                              class="btn btn-primary fs-4"
                              type="button"
                              style={{ width: "100%" }}
                            >
                              <span className="icon">
                                <ImLab />
                              </span>{" "}
                              Laboratory
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1">
                        <img
                          src="https://res.cloudinary.com/dq5upuxm8/image/upload/v1708075638/dental%20guru/Login-page_1_cwadmt.png"
                          className="img-fluid"
                          alt="Sample"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* {popupVisible && <Popup email={email} onClose={closeUpdatePopup} />} */}
      </Container>
    </>
  );
};

export default UniversalLogin;
const Container = styled.div`
.heading{
  font-family: 'Roboto', sans-serif; /* Set the font to Roboto */
}
.btn {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: white;
  background-color: #0d6efd;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out, border 0.3s ease, border-radius 0.3s ease;
  font-family: 'Roboto', sans-serif; /* Set the font to Roboto */
}

.btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%; /* Start from the left side */
  width: 100%;
  height: 100%;
  background: black; /* Set background color */
  transition: left 0.3s ease-in-out;
  z-index: -1; /* Place it behind the content */
  
  
}

.btn:hover::before {
  left: 0; /* Fill from the left side */
  
}

.btn:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-10px) scale(1.05);
  border: none;
}

.btn .icon {
  transition: transform 0.3s ease-in-out;
}

.btn:hover .icon {
  transform: scale(1.3);
}


  .cardstyle {
    border-radius: 25px;
    background-color: #d7f3f0 !important;
  }

  a {
    text-decoration: none;
  }

  .select-style {
    border: none;
    background-color: #22a6b3;
    font-weight: bold;
    color: white;
  }

  .popup-container {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
  }

  .popup-container.active {
    display: flex;
    background-color: #00000075;
    z-index: 1;
  }

  .popup {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  .icon {
    float: left;
   
  }
 
`;
