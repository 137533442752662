
import "./App.css";
import React from 'react'
import { Route, Routes } from "react-router-dom";
import UniversalLogin from "./pages/UniversalLogin";


function App() {

  return (
    <Routes>
    
 
      <Route path="/" element={<UniversalLogin />} />

    </Routes>
  );
}

export default App;
